<template>
  <div class="h-svh flex flex-col pt-20">
    <Header />

    <div class="mx-auto container">
      <Breadcrumbs />
    </div>

    <main class="flex-1">
      <slot />
    </main>

    <ModalRejoinder />

    <Footer />
  </div>
</template>
