<script setup lang="ts">
import { LocalStorageKey } from '@/types/enums'

type TAction = 'confirm' | 'cancel' | 'edit'

const { rejoinderModal, rejoinder } = useRejoinder()

const baseURL = useRuntimeConfig().public.api.awsGateway
const origin = useRuntimeConfig().public.buildContent.sellerDns
const { id: userId } = useCustomer().customer.value
const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

const date = ref<string | Date>()
const time = ref<{ name: string, code: string } | null>(null)
const editableCardIndex = ref<number | null>(null)
const options = ref([
  { name: '08:00 às 10:00', code: '08:00 às 10:00' },
  { name: '10:00 às 12:00', code: '10:00 às 12:00' },
  { name: '12:00 às 14:00', code: '12:00 às 14:00' },
  { name: '14:00 às 16:00', code: '14:00 às 16:00' },
  { name: '16:00 às 18:00', code: '16:00 às 18:00' },
])

const step = ref(1)

const requestLoading = ref({
  confirm: false,
  cancel: false,
  edit: false,
})
async function doRequest(orders: any, action: TAction) {
  try {
    let payload

    let dateToPayload

    requestLoading.value[action] = true

    if (action === 'confirm') {
      payload = {
        order_detail_ids: [orders[0].orderDetailsId],
        scheduled_date: orders[0].scheduledDate,
        schedule_type: 'confirm_schedule',
        origin,
      }
    }
    else if (action === 'cancel') {
      payload = {
        order_detail_ids: [orders[0].orderDetailsId],
        schedule_type: 'cancel_schedule',
        origin,
      }
    }
    else if (action === 'edit') {
      if (date.value && time.value) {
        const selectedTimeRange = time.value.code.split(' às ')[0]
        const [hours, minutes] = selectedTimeRange.split(':').map(Number)

        const formattedDateTime = new Date(date.value)
        formattedDateTime.setHours(hours)
        formattedDateTime.setMinutes(minutes)

        const formattedDate = formattedDateTime.toLocaleString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
        })

        const [dateTime, hour] = formattedDate.split(', ')

        const [day, month, year] = dateTime.split('/')
        dateToPayload = `${year}-${month}-${day}T${hour}`
      }
      else {
        requestLoading.value[action] = false
        return toast({
          severity: 'error',
          summary: 'Erro',
          detail: 'Por favor, selecione uma data e um horário.',
        })
      }

      payload = {
        order_detail_ids: [orders[0].orderDetailsId],
        scheduled_date: dateToPayload,
        schedule_type: 'confirm_schedule',
        origin,
      }
    }

    await $fetch(`/v1/customer/users/${userId}/orders/${orders[0].orderId}/schedule`, {
      method: 'POST',
      baseURL,
      headers: {
        AuthToken: accessToken.value as string,
        origin,
      },
      body: payload,
    })

    toast({
      severity: 'success',
      summary: 'Success',
      detail: ['confirm', 'edit'].includes(action) ? 'Agendamento confirmado com sucesso.' : 'Agendamento cancelado com sucesso.',
    })
  }
  catch (error) {
    console.error(error)
    captureException(error)
  }
  finally {
    requestLoading.value[action] = false
    reloadNuxtApp()
  }
}

function toggleEdit(index: number) {
  editableCardIndex.value = editableCardIndex.value === index ? null : index
  date.value = ''
  time.value = null
}
</script>

<template>
  <PrimeDialog
    v-model:visible="rejoinderModal"
    modal
    :draggable="false"
    dismissable-mask
    class="lg:max-w-[736px] w-[calc(100%_-_32px)] mx-4"
  >
    <template #header>
      <div class="w-full flex items-center justify-between">
        <h3 class="font-sora text-xl leading-5 text-primary-400">
          Sugestão de nova data de agendamento
        </h3>

        <span class="font-sora text-xl leading-5 text-neutral-100">
          {{ step }}/{{ Object.values(rejoinder).length }}
        </span>
      </div>
    </template>

    <template #default>
      <div v-if="rejoinder" class="flex flex-col gap-4">
        <p class="text-neutral-30 text-sm leading-5">
          Infelizmente, as datas que você selecionou previamente para o agendamento dos seus exames não estão
          disponíveis
          no momento. Abaixo seguem as novas datas para a realização dos seus procedimentos. Caso as opções não atendam
          suas necessidades o procedimento deverá ser cancelado. Por favor, escolha entre as opções abaixo:
        </p>

        <div
          v-for="(lab, labIndex) in Object.values(rejoinder).slice(step - 1)"
          :key="Object.keys(rejoinder)[labIndex]"
          class="flex flex-col gap-4"
        >
          <PrimeCard class="border border-neutral-50">
            <template #content>
              <div class="flex items-center gap-2">
                <Img :src="lab.labLogo" :alt="`${lab.labName} logo`" class="size-12 rounded" />

                <h4 class="font-sora text-medium leading-8 text-neutral-300">
                  {{ lab.labName }}
                </h4>
              </div>
            </template>
          </PrimeCard>

          <PrimeCard
            v-for="(orders, ordersIndex) in Object.values(lab.scheduledDate)"
            :key="Object.keys(lab.scheduledDate)[ordersIndex]"
            class="border border-neutral-50"
          >
            <template #content>
              <div class="flex flex-col gap-5">
                <div v-for="order in orders" :key="order.orderDetailsId" class="flex items-center justify-between py-2">
                  <span class="font-semibold text-base font-sora leading-6">{{ order.displayName }}</span>
                  <span class="font-sora font-bold text-xl leading-8 text-primary-400 whitespace-nowrap">{{ formatCurrency(order.price) }}</span>
                </div>

                <PrimeCard class="border border-neutral-50">
                  <template #content>
                    <div class="flex lg:flex-row flex-col lg:items-center gap-2">
                      <div class="gap-2 flex items-center">
                        <Icon name="mdi:calendar-month-outline" class="min-w-[24px] w-5 h-5 text-primary-500" />
                        <span class="text-primary-500 text-xs font-semibold leading-4">Data e horário</span>
                      </div>
                      <span class="text-neutral-300 text-base leading-6">
                        {{ formatDate(Object.keys(lab.scheduledDate)[ordersIndex]) }} - {{ formatTime(Object.keys(lab.scheduledDate)[ordersIndex]) }}
                      </span>
                    </div>
                  </template>
                </PrimeCard>

                <div v-if="editableCardIndex === ordersIndex" class="flex items-center justify-between gap-4 mt-4">
                  <div class="flex flex-col gap-1 w-full">
                    <label for="date" class="font-sora text-sm leading-5 text-neutral-200">
                      Data sugerida
                    </label>

                    <PrimeDatePicker
                      v-model="date"
                      :min-date="new Date()"
                      :default-value="Object.keys(lab.scheduledDate)[ordersIndex]"
                      inputId="date"
                      dateFormat="dd/mm/yy"
                    />
                  </div>

                  <div class="flex flex-col gap-1 w-full">
                    <label for="hour" class="font-sora text-sm leading-5 text-neutral-200">
                      Horário
                    </label>

                    <PrimeSelect
                      v-model="time"
                      inputId="hour"
                      :options="options"
                      fluid
                      optionLabel="name"
                      class="w-full md:w-56"
                    />
                  </div>
                </div>

                <div class="flex flex-col lg:flex-row items-center justify-between mt-4">
                  <div class="w-full flex flex-col lg:flex-row items-center gap-3">
                    <Button
                      variant="outlinedSecondary"
                      class="w-full lg:w-fit"
                      @click="toggleEdit(ordersIndex)"
                    >
                      {{ editableCardIndex === ordersIndex ? 'Cancelar edição' : 'Alterar data' }}
                    </Button>

                    <Button
                      v-if="editableCardIndex !== ordersIndex"
                      class="w-full lg:w-fit"
                      :loading="requestLoading.confirm"
                      :disabled="requestLoading.cancel || requestLoading.edit"
                      @click="() => doRequest(orders, 'confirm')"
                    >
                      Confirmar agendamento
                    </Button>

                    <Button
                      v-else
                      class="w-full lg:w-fit"
                      :loading="requestLoading.edit"
                      :disabled="requestLoading.cancel || requestLoading.confirm"
                      @click="() => doRequest(orders, 'edit')"
                    >
                      Confirmar nova data
                    </Button>
                  </div>

                  <Button
                    variant="textSecondary"
                    class="flex items-center gap-2 mt-4 whitespace-nowrap"
                    :loading="requestLoading.cancel"
                    :disabled="requestLoading.confirm || requestLoading.edit"
                    @click="() => doRequest(orders, 'cancel')"
                  >
                    <Icon name="mdi:close" class="size-4" />
                    <span>Cancelar agendamento</span>
                  </Button>
                </div>
              </div>
            </template>
          </PrimeCard>
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>
